import { ApartmentClient } from './apartment-client';
import { Customer } from './customer';
import { Model } from './model';

export class User extends Model<User> {
  name: string;
  password?: string;
  status?: number;

  mail?: string;
  mobile?: string;
  address?: string;
  gender?: string;
  zip?: string;
  city?: string;
  state?: string;

  phonePrivate?: string;
  phoneWork?: string;

  hmscard?: string; // as in the backest of the ends, this should be removed at some point
  hmscardExpire?: string; // as in the backest of the ends, this should be removed at some point

  hmsCard?: string;
  hmsCardExpire?: string;

  locale?: string;

  CustomerId: number;
  Customer?: Customer;

  dateOfBirth?: string;
  organizationalNumber?: string;

  mailNewCase: boolean;
  mailNewMessage: boolean;
  mailChecklist: boolean;
  mailNewRequest: boolean;

  showTextOnButtons?: boolean;
  useExtendedFileViewer?: boolean | null;
  startpage?: string | null;

  notes?: string | null;

  paymentStatus?: number;

  ApartmentClient?: ApartmentClient;

  digestHourly?: boolean;
  digestDaily?: boolean;

  /**
   * Should show and use notifications
   */
  pushNotifications?: boolean;
}
